import React, { useEffect, useState } from "react"
import axios from "axios"
import { capitalizeFirstLetter, parseTimeForBookingsSummary } from "../utils"
import moment from "moment"
import { ButtonPrimary } from "../../core/Buttons"
import BookingsCard from "./BookingsCard"
import PageSectionGridContainer from "../PageSectionGridContainer"
import { segmentAction } from "../../hooks/useSegmentTrack"

const BookingsModalGroupClassSelections = ({
  tenantId,
  tenantInfo,
  style,
  setView,
  selectedBooking,
  setBookingInfo,
  groupClassInstanceInfo,
  setGroupClassInstanceInfo,
  setTimeZone,
  timezone,
  onEditTime,
}) => {
  const { id, isGroupClass } = selectedBooking || {}
  const [classInstances, setClassInstances] = useState(new Array())
  const [classesLoaded, setClassesLoaded] = useState(false)

  useEffect(() => {
    if (id && isGroupClass) {
      // call for list of classes
      const futureMonth = moment().add(12, "M").format("MM-DD-YYYY");

      const baseURL = process.env.GATSBY_SPARKLE_API_PATH;
      const apiCode = process.env.GATSBY_SPARKLE_API_CODE;
      const api = axios.create({ baseURL });
      api
        .post(`ListBookings?code=${apiCode}&public=true`, {
          tenantId: tenantId,
          projectId: id,
          endDate: futureMonth,
        })
        .then((response) => {
          if (response.data) {
            const allClassInstances = response.data;
            const ignoreStartTimeCheck = tenantId === '08144890-f416-4a06-9119-6867f68dcfbf===2301420000008189001'

            // filters all class instances to only future, non-cancelled classes with available seats
            const filteredClassInstances = allClassInstances.filter((o) => {
              const { group_booking } = o.attributes || {};

              const start = moment(o.attributes.event.start, "YYYY-MM-DDTh:mm:ssZZ");
              const end = o.attributes.event?.end
              ? moment(o.attributes.event.end, "YYYY-MM-DDTh:mm:ssZZ")
              : null; // Handle end date safely

              const timeCondition = ignoreStartTimeCheck
                ? end
                  ? end > moment() // allow if the end time hasn't passed
                  : true // assume it's valid if end date is missing
                : start > moment(); // normal condition: check start time

              return (
                o.state !== "cancelled" &&
                o.attributes &&
                o.attributes.event &&
                timeCondition &&
                group_booking.max_seats > group_booking.current_seats
              );
            });

            function sortEventsByDate(eventArray) {
              const sortedArray = (eventArray || []).sort(function (a, b) {
                var dateA = new Date(a.attributes.event.start),
                  dateB = new Date(b.attributes.event.start);
                return dateA - dateB; // sort by date ascending
              });
              return sortedArray;
            }

            const sortedInstances = sortEventsByDate(filteredClassInstances);
            setClassInstances(sortedInstances);
          }

          setClassesLoaded(true);
        });
    }
  }, [id, isGroupClass]);

  useEffect(() => {
    if (classesLoaded && classInstances.length === 0) {
      setView("manual-contact")
    }
  }, [classesLoaded, classInstances])

  // helper function to check if class if full
  const hasAvailableSeats = (classInstanceId) => {
    const selectedGroupClass = classInstances.find(
      (o) => o.id === classInstanceId
    )
    // console.log({ selectedGroupClass })
    const { group_booking } = selectedGroupClass.attributes || {}
    const { max_seats, current_seats, available_seats } = group_booking || {}

    // does available_seats currently work in prod?
    // return available_seats > 0
    return max_seats - current_seats > 0
  }

  return !classesLoaded ? (
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  ) : (
    <PageSectionGridContainer>
      {classInstances.map((o) => {
        const { id, attributes } = o || {}
        const { event } = attributes || {}
        const { date, time, fromNow } = parseTimeForBookingsSummary(
          { ...event, customFormat: "YYYY-MM-DDTh:mm:ssZZ" } || {}
        )
        const { start, end, what: name, where, description } = event
        const { group_booking } = attributes || {}
        const { max_seats, current_seats } = group_booking || {}

        if (!hasAvailableSeats(id)) {
          setView("manual-contact")
        }

        return (
          <BookingsCard
            key={o.id}
            id={o.id}
            booking={{
              name: date,
              specificTime: time,
              fromNow,
              maxSeats: max_seats,
              currentSeats: current_seats,
              where: where.includes("http") ? "Online" : where,
              type: "Group",
            }}
            onClick={(e) => {
              e.preventDefault()
              segmentAction.track(
                `Sparkles Booking Group Class Timeslot Click`,
                {
                  bookingId: o.id,
                },
                { tenantInfo: tenantId }
              )

              const nylasBookingInfo = {
                resources: [{ id: null, timezone }],
                start: moment(start, "YYYY-MM-DDTh:mm:ssZZ"),
                end: moment(end, "YYYY-MM-DDTh:mm:ssZZ"),
                timezone,
                booking: {
                  graph: "group_owner",
                  id: o.id,
                  resource: null,
                },
              }

              setGroupClassInstanceInfo(o.attributes)
              setBookingInfo(nylasBookingInfo)
              setTimeZone(nylasBookingInfo.timezone)
              setView("contact")
            }}
          />
        )
      })}
    </PageSectionGridContainer>
  )
}

export default BookingsModalGroupClassSelections
